import * as confetti from 'canvas-confetti';

const setStyles = (el, styles) => {
  Object.keys(styles).forEach((key) => {
    el.style[key] = styles[key];
  });
};
const transitionEnd = (el, callback) => {
  const onTransitionEnd = (...args) => {
    if (args[0] && args[0].target !== el) return;
    el.removeEventListener('transitionend', onTransitionEnd);
    callback.call(el, ...args);
  };
  el.addEventListener('transitionend', onTransitionEnd);
};

export default class PickAPresent {
  constructor(coupon) {
    this.holder = document.querySelector('.om-pick-a-present-test');
    this.presentContainer = document.querySelector('.om-pick-a-present');
    this.presentAttentionSeekerInterval = 0;
    this.attentionSeekerPresentIndex = 0;
    this.attentionSeekerPresentDirection = 'right';
    this.coupon = coupon;

    this.setPresents();
    this.initListeners();
  }

  setPresents() {
    this.presents = this.presentContainer.querySelectorAll('.om-present');
    this.setCouponInPresents();
    this.presentAttentionSeekerInterval = setInterval(() => {
      this.playPresentsAttentionSeeker();
    }, 1500);
    this.presents.forEach((present) => {
      present.classList.add('om-present-active');
    });
  }

  setCouponInPresents() {
    this.presents.forEach((present) => {
      const couponText = present.querySelector('.om-present-coupon-holder div');
      if (couponText) {
        couponText.textContent = this.coupon.title;
      }
    });
  }

  initListeners() {
    const self = this;
    this.presents.forEach((present) => {
      present.addEventListener('click', this.onPresentClick.bind(self), false);
    });
  }

  onPresentClick(event) {
    if (this.completed) {
      return;
    }

    this.removeAttentionSeekerInterval();

    const selectedPresent = event.currentTarget.closest('.om-present');
    const selectedPresentIndex = selectedPresent.dataset.presentKey;

    this.hidePickAPresentTitles();
    this.hideUnselectedPresents(selectedPresentIndex);

    selectedPresent.classList.remove('om-present-active');

    this.animatePresent(selectedPresent, () => {
      this.animateBoxOpening(selectedPresent, () => {});
    });

    selectedPresent.removeEventListener('click', this.onPresentClick);
    this.completed = true;
  }

  hidePickAPresentTitles() {
    const title = document.querySelector('.om-pick-a-present-title');
    const subtitle = document.querySelector('.om-pick-a-present-subtitle');

    this.fadeOutElement(title);
    this.fadeOutElement(subtitle);
  }

  fadeOutElement(element) {
    if (element) {
      setStyles(element, {
        transitionDuration: '300ms',
        transitionProperty: 'opacity',
        opacity: 0,
      });
    }
  }

  hideUnselectedPresents(selectedIndex) {
    this.presents.forEach((present) => {
      if (present.dataset.presentKey !== selectedIndex) {
        this.fadeOutElement(present);
      }
    });
  }

  playPresentsAttentionSeeker() {
    const present = this.presents[this.attentionSeekerPresentIndex];

    present.classList.add('animated', 'present-bounce');

    const doIt = () => {
      present.classList.remove('animated', 'present-bounce');
      present.removeEventListener('animationend', doIt);
    };

    present.addEventListener('animationend', doIt);

    if (this.attentionSeekerPresentIndex === this.presents.length - 1) {
      this.attentionSeekerPresentDirection = 'left';
    }

    if (this.attentionSeekerPresentIndex === 0) {
      this.attentionSeekerPresentDirection = 'right';
    }

    if (this.attentionSeekerPresentDirection === 'right') {
      this.attentionSeekerPresentIndex++;
    } else {
      this.attentionSeekerPresentIndex--;
    }
  }

  removeAttentionSeekerInterval() {
    if (this.presentAttentionSeekerInterval) {
      clearInterval(this.presentAttentionSeekerInterval);
      this.presents.forEach((p) => {
        p.classList.remove('om-animated', 'om-present-bounce');
      });
    }
  }

  addStylesheet() {
    const css = /* css */ `
        .om-pick-a-present-animate-shake {
          animation: om-pick-a-present-shake-animation 200ms forwards;
          animation-iteration-count: 5;
        }
        @keyframes om-pick-a-present-shake-animation {
          0% {
            transform: translateX(0px)
          }
          50% {
            transform: translateX(35px)
          }
          100% {
            transform: translateX(0px)
          }
        }
      `;
    let stylesheet = document.querySelector('style#om-pick-a-present-animation');
    if (!stylesheet) {
      stylesheet = document.createElement('style');
      stylesheet.textContent = css;
      stylesheet.id = 'om-pick-a-present-animation';
      document.head.appendChild(stylesheet);
    }
  }

  animatePresent(present, onComplete) {
    const presentSvg = present.querySelector('svg');
    const largePresentSize = this.holder.offsetHeight * 0.5;
    const presentOffsetLeft = present.offsetLeft;
    const presentWidth = present.offsetWidth;
    const presentHeight = present.offsetHeight;
    const parentWidth = present.parentElement.offsetWidth;
    const parentHeight = present.parentElement.offsetHeight;
    const presentSvgHeight = presentSvg.clientHeight;
    const presentTranslate = parentWidth / 2 - presentOffsetLeft;
    const presentTranslateY = parentHeight / 2;
    const presentScale = largePresentSize / presentWidth;

    const onTransitionEnd = () => {
      // unset props
      setStyles(present, {
        transitionDelay: '',
        transitionDuration: '',
        transform: '',
        left: '50%',
        top: `calc(50% - ${presentTranslateY}px)`,
        marginLeft: `-${largePresentSize / 2}px`,
        marginTop: `-${largePresentSize / 2}px`,
      });
      setStyles(presentSvg, {
        width: `${largePresentSize}px`,
        height: `${largePresentSize}px`,
        marginTop: `-${(presentHeight - presentSvgHeight) * 2}px`,
      });
      onComplete();
    };
    transitionEnd(present, onTransitionEnd);
    setStyles(present, {
      transitionDelay: '300ms',
      transitionDuration: '800ms',
      transitionProperty: 'transform',
    });
    setTimeout(() => {
      setStyles(present, {
        position: 'static',
        left: `${presentOffsetLeft}px`,
        marginRight: '0px',
        transform: `translate(${presentTranslate}px, -${presentTranslateY}px) scale(${presentScale})`,
      });
    }, 300);
  }

  getSelectedPresentTopOffset() {
    const { top: containerTop } = this.presentContainer.getBoundingClientRect();
    const { top: pageTop } = document
      .querySelector('.om-pick-a-present-test-modal')
      .getBoundingClientRect();
    return containerTop - pageTop;
  }

  animateBoxOpening(present, onComplete) {
    const self = this;
    if (present) {
      const ribbon = present.querySelector('.om-present-svg-ribbon');
      const ribbonSide = present.querySelector('.om-present-svg-ribbon-side');
      const top = present.querySelector('.om-present-svg-box-top');
      const topShadow = present.querySelector('.om-present-svg-box-top-shadow');
      const couponHolder = present.querySelector('.om-present-coupon-holder');
      const couponHolderChild = couponHolder.childNodes[0];
      const couponHolderParent = couponHolder.parentNode;
      const couponHolderChildWidth = couponHolderChild.offsetWidth;
      const couponHolderParentWidth = couponHolderParent.offsetWidth;
      const couponHolderChildHeight = couponHolderChild.offsetHeight;
      const couponHolderOffset = couponHolder.offsetTop;
      const presentContainerWidth = this.presentContainer.offsetWidth;
      setStyles(couponHolder, {
        transitionDuration: '1000ms',
        transform: `translateY(0px) scale(0)`,
      });
      setStyles(present, {
        position: 'absolute',
      });
      const confetti = () => {
        this.animateConfetti();
        setTimeout(() => {
          if (couponHolderChildWidth >= presentContainerWidth) {
            setStyles(couponHolder, {
              width: `${presentContainerWidth}px`,
            });
            couponHolder.classList.add('om-present-coupon-long-title');
            const longTitle = present.querySelector('.om-present-coupon-long-title');
            const longTitleHeight = longTitle.offsetHeight;
            setStyles(couponHolder, {
              transform: `translate(${
                -this.presentContainer.offsetWidth / 2 + couponHolderParentWidth / 2
              }px,${-couponHolderOffset - longTitleHeight}px) scale(1)`,
            });
          } else {
            setStyles(couponHolder, {
              transform: `translateY(${-couponHolderOffset - couponHolderChildHeight}px) scale(1)`,
            });
          }

          setTimeout(() => {
            onComplete();
          }, 1500);
        }, 500);
      };
      const openBoxRibbon = () => {
        setStyles(ribbon, {
          transitionDuration: '600ms',
          transform: 'translateY(1000%)',
        });
        setStyles(ribbonSide, {
          transitionProperty: 'transform',
          transitionDuration: '600ms',
          transform: 'translateY(1000%)',
        });
        setTimeout(() => {
          confetti();
        }, 1200);
      };
      const openBoxTop = () => {
        const onTopOpened = () => {
          setStyles(top, {
            transitionDuration: '1000ms',
            transform: 'translateX(-200%) translateY(1000%) rotate(-90deg)',
          });
        };
        setStyles(topShadow, {
          transitionDuration: '200ms',
          opacity: '0',
        });
        setStyles(top, {
          transitionDuration: '1000ms',
          transform: 'translateY(-20%)',
        });
        transitionEnd(top, onTopOpened);
      };
      const openBox = () => {
        openBoxTop();
        openBoxRibbon();
      };
      const shakeBox = () => {
        self.addStylesheet();
        const onAnimationEnd = () => {
          present.removeEventListener('animationend', onAnimationEnd);
          openBox();
        };
        present.addEventListener('animationend', onAnimationEnd);
        present.classList.add('om-pick-a-present-animate-shake');
      };
      shakeBox();
    }
  }

  animateConfetti() {
    confetti.create()({
      particleCount: 100,
      spread: 70,
      zIndex: 200003,
      startVelocity: 30,
      origin: {
        y: 0.6,
      },
    });
  }
}
